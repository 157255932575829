import { useState } from 'react';
import './index.scss';
import { CognyIcon } from '~/assets/icons';
import { IntlShape, injectIntl } from 'react-intl';
import {
    Button,
    Spinner,
    TextField,
    ToastAlert,
} from '../../components/UIElements';
import { AlertCircle, Mail, Lock } from 'react-feather';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import RecoveryPasswordModal from './RecoveryPasswordModal';
import { useNavigate } from 'react-router-dom';
import { getErrorCode } from '../../utils/getErrorCode';
import toast from 'react-hot-toast';
import { authenServices } from '~/services';
import { CreateSessionError } from '~/services/authenServices';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from '../../components/Router/paths';
import { hotjar } from 'react-hotjar';
import analytics from '~/helpers/analytics';
import teamWorkAnimation from '~/assets/images/animated-teamwork.png';
import womanWriting from '~/assets/images/woman-writing.png';
import { useEntityContext, useTemporaryErrorDisplay } from '~/hooks';
import { AuthContextInterface } from '~/interfaces/contexts';
import { AuthContext } from '~/context/auth';
import { LoginUserDTO } from '~/interfaces/entities';

const initialValues = {
    email: '',
    password: '',
};

const Login = ({ intl }: { intl: IntlShape }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [accountIsDisabled, setAccountIsDisabled] = useState(false);

    const [showRecoveryModal, setShowRecoveryModal] = useState(false);

    const { handleError, error } = useTemporaryErrorDisplay();

    const authContext = useEntityContext<AuthContextInterface>(AuthContext);

    const validateSchema = Yup.object().shape({
        email: Yup.string().required(
            intl.formatMessage({ id: 'required_field' })
        ),
        password: Yup.string().required(
            intl.formatMessage({ id: 'required_field' })
        ),
    });

    const onSubmit = async (credentials: LoginUserDTO) => {
        setLoading(true);
        handleError('');
        setAccountIsDisabled(false);

        const { code, internalError } = await authContext.login(credentials);
        setLoading(false);

        if (internalError === CreateSessionError.disabled_user) {
            setAccountIsDisabled(true);
            return;
        }
        if (
            internalError === CreateSessionError.bad_credentials ||
            internalError === CreateSessionError.workspace_error
        ) {
            handleError(intl.formatMessage({ id: getErrorCode(code || '') }));
            return;
        }

        const { user } = authContext;
        if (!user) return;

        const trackingPayload = {
            email: user.email,
            first_name: user.first_name,
            last_name: user.last_name,
        };

        // record the user data in hotjar
        if (hotjar.initialized()) {
            hotjar.identify(user.id, trackingPayload);
        }

        // record the user data in June
        analytics.trackLogin(user.id, trackingPayload);

        navigate(PRIVATE_ROUTES.search);
    };

    const resendConfirmationLink = async () => {
        setLoading(true);

        const [error, response] =
            await authenServices.resendConfirmationLink(email);

        setLoading(false);

        if (error) {
            toast.error(
                intl.formatMessage({ id: getErrorCode(response.code) })
            );
            return;
        }

        toast.success(intl.formatMessage({ id: 'link_sent_successfully' }));
    };
    const formik = useFormik({
        initialValues,
        validationSchema: validateSchema,
        onSubmit,
    });

    const { email, password } = formik.values;

    return (
        <div className="dg-login-page">
            <ToastAlert position="bottom-center" />

            {showRecoveryModal && (
                <RecoveryPasswordModal
                    handleClose={() => setShowRecoveryModal(false)}
                    intl={intl}
                />
            )}

            <div className="dg-login-page__content animate__animated animate__fadeIn">
                <div>
                    <CognyIcon />
                </div>
                <div className="dg-login-page__content__top">
                    <div className="dg-login-page__content__info">
                        <h2> {intl.formatMessage({ id: 'welcome_back' })}! </h2>
                        <div>
                            <p>
                                {intl.formatMessage({
                                    id: 'login_cogny_aclaration_one',
                                })}
                            </p>
                            <p>
                                {intl.formatMessage({
                                    id: 'login_cogny_aclaration_two',
                                })}
                            </p>
                        </div>
                    </div>

                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            label={intl.formatMessage({ id: 'email' })}
                            iconBefore={Mail}
                            inputProps={{
                                value: email,
                                onChange: formik.handleChange,
                                name: 'email',
                                type: 'text',
                                placeholder:
                                    intl.formatMessage({ id: 'email' }) + '...',
                            }}
                            error={formik.errors.email}
                        />
                        <TextField
                            label={intl.formatMessage({ id: 'password' })}
                            iconBefore={Lock}
                            inputProps={{
                                value: password,
                                onChange: formik.handleChange,
                                name: 'password',
                                placeholder:
                                    intl.formatMessage({ id: 'password' }) +
                                    '...',
                            }}
                            error={formik.errors.password}
                            isPassword
                        />

                        {error && (
                            <span className="dg-login-page__error">
                                {error}
                            </span>
                        )}

                        <span onClick={() => setShowRecoveryModal(true)}>
                            {intl.formatMessage({ id: 'forgot_password' })}?
                        </span>

                        <Button
                            disabled={loading}
                            variant="accent"
                            type="submit"
                            onClick={formik.handleSubmit}
                        >
                            {loading ? (
                                <Spinner width="15" height="15" weight="2" />
                            ) : (
                                intl.formatMessage({ id: 'sign_in' })
                            )}
                        </Button>

                        {accountIsDisabled && (
                            <div className="dg-login-page__account-disabled  animate__animated animate__fadeIn">
                                <AlertCircle />
                                <div>
                                    <div>
                                        <p>
                                            {intl.formatMessage({
                                                id: 'account_disabled',
                                            })}
                                            .<br></br>
                                            {intl.formatMessage({
                                                id: 'confirm_to_continue_using_account',
                                            })}
                                            .
                                        </p>

                                        <p>
                                            {intl.formatMessage({
                                                id: 'account_disabled_description',
                                            })}
                                            .
                                        </p>
                                    </div>

                                    {loading ? (
                                        <div>
                                            <Spinner
                                                width={20}
                                                height={20}
                                                weight={'2'}
                                            />
                                        </div>
                                    ) : (
                                        <p>
                                            {intl.formatMessage({
                                                id: 'cant_find_confirmation_link',
                                            })}
                                            .<br></br>
                                            <span
                                                onClick={resendConfirmationLink}
                                            >
                                                {intl.formatMessage({
                                                    id: 'resend_link',
                                                })}
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                    </form>
                </div>

                <div className="dg-login-page__content__bottom">
                    <span> {intl.formatMessage({ id: 'new_here' })}? </span>
                    <Button
                        variant="secondary"
                        onClick={() => navigate(PUBLIC_ROUTES.signup)}
                    >
                        {intl.formatMessage({ id: 'create_account' })}
                    </Button>
                </div>
            </div>
            <div className="dg-login-page__image">
                <img alt="remote_work" src={teamWorkAnimation} />
                <img alt="woman_writing" src={womanWriting} />
            </div>
        </div>
    );
};

const WrappedComponent = injectIntl(Login);

export default WrappedComponent;
